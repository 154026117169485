<template>
  <div id="page">
    <header-bar zzz-show-back-btn="false" />
    <footer-bar />
    <all-menus />
    <div class="page-content header-clear-medium">
  
    </div>
  </div>
</template>

<script lang="ts">
import $ from "jquery";
import AllMenus from "@/components/all-menus.vue";
import FooterBar from "@/components/footer-bar.vue";
import HeaderBar from "@/components/header-bar.vue";

import { Options, Vue } from 'vue-class-component';

// @ is an alias to /src
@Options({
  components: {
    HeaderBar,
    FooterBar,
    AllMenus,
  },
})
export default class ProjectSettings extends Vue {

  public async mounted() {
    window.ww.theme.initAll();
  }
}
</script>