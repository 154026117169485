
import { formatDuration, formatRelative } from "date-fns";

import {
  ProjectStatus,
  ProjectPhase,
  Project,
  WritingSession,
} from "@/shared/projects";
import { ProjectService } from "@/services/projectService";
import SessionModal from "@/components/session-modal.vue";
import StickyPage from "@/components/sticky-page.vue";
import { LogService } from "@/services/logService";
import { defineComponent } from '@vue/runtime-core';

export default defineComponent({
  name: "ProjectDetailsView",
  components: { StickyPage, SessionModal },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      projectService: {} as ProjectService,
      currentProject: {} as Project | null,
      currentLocation: {} as any,
      ls: new LogService(),
      totalWords: 0,
      totalDuration: "",
      durationShort: "",
      completePercent: 0,
      lastActive: "",
      isLoading: false,
    };
  },
  computed: {
    progressStatus() {
      if (
        !this.currentProject ||
        !this.currentProject.targetWordCount ||
        !this.currentProject.targetEndDate ||
        !this.currentProject.startDate
      ) {
        return "unknown";
      }
      const timelineLen =
        this.currentProject.targetEndDate.getTime() -
        this.currentProject.startDate.getTime();
      const timeSpent = Date.now() - this.currentProject.startDate.getTime();
      const timelinePercent = timeSpent / timelineLen;

      if (this.completePercent < timelinePercent) {
        if (this.completePercent + 15 < timelinePercent) {
          return "red";
        }
        return "yellow";
      }
      return "green";
    },

    statusColor() {
      const colors = {
        red: "bg-red2-dark",
        yellow: "bg-yellow2-dark",
        green: "bg-green2-dark",
        unknown: "",
      };
      return colors[this.progressStatus];
    },

    statusMessage() {
      const msgs = {
        red: "In Jeopardy",
        yellow: "Behind",
        green: "On Track",
        unknown: "",
      };
      return msgs[this.progressStatus];
    },
    
    wordsPerSession(): number {
      return (
        this.totalWords / (this?.currentProject?.writingSessions?.length || 1)
      );
    },

    totalSessions(): number {
      return this.currentProject?.writingSessions?.length || 0;
    },
  },

  async created() {
    this.projectService = new ProjectService();
    await this.loadProject();
  },

  async mounted() {
    window.ww.theme.initAll();
  },

  methods: {
    async loadProject() {
      const cp = await this.projectService.getProject(this.id);
      if (!cp) {
        //this.$snack({msg:`Project: ${this.id} not found`, type: 'error'});
        this.$router.push({ name: "oops", params: { resource: "Project" } });
        return;
      }
      let td = 0,
        tw = 0;
      cp.writingSessions?.forEach((ws:WritingSession) => {
        const end = ws.endTime || new Date();
        let dur = end.getTime() - new Date(ws.startTime).getTime();
        if (ws.pauses) {
          ws.pauses.forEach((p) => {
            dur -= p.end
              ? new Date(p.end)?.getTime() - new Date(p.start).getTime()
              : 0;
          });
        }
        dur = Math.floor(dur / 1000);
        td += dur;
        tw += ws.wordCount;
      });
      this.totalWords = tw;
      // this.totalDuration = formatDuration({
      //   years: Math.round(td / (24 * 3600 * 30 * 12)),
      //   months: Math.round((td / (24 * 3600 * 30)) % 12),
      //   days: Math.round((td / (24 * 3600)) % 30),
      //   hours: Math.round((td / 3600) % 24),
      //   minutes: Math.round((td / 60) % 60),
      //   seconds: Math.round(td % 60),
      // });
      const hours = Math.floor(td / 3600);
      const minutes = Math.floor(td / 60) % 60;
      this.totalDuration = `${hours} hour${
        hours > 1 ? "s" : ""
      }, ${minutes} minute${minutes > 1 ? "s" : ""}`;
      this.durationShort = `${hours < 10 ? "0" + hours : hours}:${
        minutes < 10 ? "0" + minutes : minutes
      }`;
      this.lastActive = formatRelative(
        new Date(cp.lastModified || new Date()),
        new Date()
      );
      this.completePercent =
        Math.round(((tw || 0) / (cp.targetWordCount || 1)) * 1000) / 10;
      this.currentProject = cp;
    },

    async startTimer() {
      this.isLoading = true;

      const modal = this.$refs["sm"] as SessionModal; //TODO: so bad
      await modal.openModal();
    },

    async sessionComplete(session: WritingSession) {
      await this.projectService.saveSession(session);
      await this.loadProject();
    },

    loadLocation() {
      if (!navigator.geolocation) {
        return;
      }
      navigator.geolocation.getCurrentPosition(
        (coords) => {
          this.currentLocation = coords;
        },
        (err) => {
          this.ls.error(err.message);
        }
      );
    },
  },
});
