
// @ is an alias to /src
import $ from "jquery";
import Chart from "chart.js";
import { Options, Vue } from "vue-class-component";
import { ProjectStatus, ProjectPhase, Project } from "@/shared/projects";
import { ProjectService } from "@/services/projectService";
import NewProject from "@/components/new-project.vue";
import StickyPage from "@/components/sticky-page.vue";
import { formatRelative } from "date-fns";

interface ProjView extends Project {
  lastActiveString: string;
}

@Options({
  name:"ProjectView",
  components: { NewProject, StickyPage },
})
export default class ProjectsView extends Vue {
  private tab: any = undefined;
  public totalProjects = 5;
  public projects: Array<Project> = [];
  private projectService: ProjectService = new ProjectService();
  get activeProjects() {
    return this.projects.filter((proj) => proj.state === ProjectStatus.active);
  }

  public async created() {
    this.projects = [];
    const projArr = await this.projectService.getProjects();
    projArr.forEach(this.loadProject);
  }

  private loadProject(proj: Project) {
    const p = proj as ProjView;
    p.lastActiveString = formatRelative(new Date(p.lastModified), new Date());
    this.projects.push(p);
  }

  public async mounted() {
    window.ww.theme.initAll(this.$el);
  }
  public async newProjectCreated(project: Project) {
    project.lastModified = Date.now();
    this.projectService.saveProject(project);
    this.loadProject(project);
  }

  public async beforeRouteEnter(to: any, from: any, next: any) {
    console.log("do you even wait bro");
  }
  public async beforeRouteUpdate(to: any, from: any, next: any) {
    console.log("do you even wait bro");
  }
  public async beforeRouteLeave(to: any, from: any, next: any) {
    console.log("do you even wait bro3");
  }
}
