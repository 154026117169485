<template>
  <sticky-page>
    <template v-slot:add-menus>
      <new-project id="newProjectMenu" @save="newProjectCreated"></new-project>
    </template>
    <div class="container">
      <div class="row justify-content-center">
        >
        <div class="card card-style col-md-6">
          <div class="content mb-0">
            <!-- <fa
            icon="chart-bar"
            class="float-right font-20 color-green1-dark"
            style="margin-top:-5px"
          /> -->
            <h2 class="text-center bolder">My Projects</h2>

            <p class="text-center mb-4 mt-n2 font-11 color-dark1-light">
              {{ projects.length || 0 }} projects currently tracked by the
              Warden
            </p>
            <a
              href="#"
              data-menu="newProjectMenu"
              class="btn btn-m btn-center-l text-uppercase font-900 bg-red2-dark rounded-sm shadow-xl mt-4 mb-4"
              :class="{ 'scale-box': !projects.length }"
              >New Project</a
            >
            <div class="clearfix mb-3"></div>
            <!-- <div
            class="tab-controls tabs-round tab-animated tabs-small tabs-rounded shadow-xl mt-4"
            data-tab-items="2"
            data-tab-active="bg-highlight activated color-white"
          >
            <a href="#" data-tab-active data-tab="tab-1">
              <fa icon="book-open" class="mr-2" />
            </a>
            <a href="#" data-tab="tab-2">
              <fa :icon="['fad', 'book-alt']" class="mr-2" />
            </a>
          </div>
          <div class="clearfix mb-3"></div> -->
          </div>
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="card card-style col-md-6" v-if="projects.length">
          <div class="content mb-2">
            <!-- <h3>Active Projects</h3>
          <p>
            A list that contains 2 icons, and a text. ideal for a small patch of text and icons.
            These support background colors, classic simple lists styles, or can support badges and even switches!
          </p>-->

            <div class="list-group list-custom-large">
              <router-link
                :to="'/projects/' + project.id"
                v-for="project in activeProjects"
                :key="project.id"
                :class="{
                  'border-0': project.id === activeProjects.slice(-1)[0].id,
                }"
              >
                <i class="color-gray2-dark">
                  <fa icon="book-open" size="2x"></fa>
                </i>
                <span>{{ project.name }}</span>
                <strong>{{ project.lastActiveString }}</strong>
                <!-- <i class="fa fa-angle-right"></i> -->
                <i>
                  <fa icon="angle-right" />
                </i>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </sticky-page>
</template>

<script lang="ts">
// @ is an alias to /src
import $ from "jquery";
import Chart from "chart.js";
import { Options, Vue } from "vue-class-component";
import { ProjectStatus, ProjectPhase, Project } from "@/shared/projects";
import { ProjectService } from "@/services/projectService";
import NewProject from "@/components/new-project.vue";
import StickyPage from "@/components/sticky-page.vue";
import { formatRelative } from "date-fns";

interface ProjView extends Project {
  lastActiveString: string;
}

@Options({
  name:"ProjectView",
  components: { NewProject, StickyPage },
})
export default class ProjectsView extends Vue {
  private tab: any = undefined;
  public totalProjects = 5;
  public projects: Array<Project> = [];
  private projectService: ProjectService = new ProjectService();
  get activeProjects() {
    return this.projects.filter((proj) => proj.state === ProjectStatus.active);
  }

  public async created() {
    this.projects = [];
    const projArr = await this.projectService.getProjects();
    projArr.forEach(this.loadProject);
  }

  private loadProject(proj: Project) {
    const p = proj as ProjView;
    p.lastActiveString = formatRelative(new Date(p.lastModified), new Date());
    this.projects.push(p);
  }

  public async mounted() {
    window.ww.theme.initAll(this.$el);
  }
  public async newProjectCreated(project: Project) {
    project.lastModified = Date.now();
    this.projectService.saveProject(project);
    this.loadProject(project);
  }

  public async beforeRouteEnter(to: any, from: any, next: any) {
    console.log("do you even wait bro");
  }
  public async beforeRouteUpdate(to: any, from: any, next: any) {
    console.log("do you even wait bro");
  }
  public async beforeRouteLeave(to: any, from: any, next: any) {
    console.log("do you even wait bro3");
  }
}
</script>
// <style lang="scss">
// @import "./src/scss/components/_list-group.scss";
//
</style>