<template>
  <div
    class="menu menu-box-bottom menu-box-detached rounded-m"
    data-menu-height="420"
    data-menu-effect="menu-over"
  >
    <!-- <h1 class="text-center text-uppercase font-900 mt-3 pt-2">Project Type</h1>
    <p class="boxed-text-xl under-heading">First choose the type of writing project</p>
    <div class="divider divider-margins"></div>

    <div class="row text-center mr-4 ml-4 mb-0">
      <div class="col-4 mb-n2">
        <a href="#" class="icon icon-l bg-facebook rounded-s shadow-l">
          <i class="fab fa-book-f font-22"></i>
          <br />
        </a>
        <p class="font-11 opacity-70">Novel</p>
      </div>
      <div class="col-4 mb-n2">
        <a href="#" class="icon icon-l bg-twitter rounded-s shadow-l">
          <i class="fab fa-book font-22"></i>
          <br />
        </a>
        <p class="font-11 opacity-70">Childrens</p>
      </div>
      <div class="col-4 mb-n2">
        <a href="#" class="icon icon-l bg-linkedin rounded-s shadow-l">
          <i class="fab fa-linkedin-in font-22"></i>
          <br />
        </a>
        <p class="font-11 opacity-70">Short Story</p>
      </div>
      <div class="col-4 mb-n2">
        <a href="#" class="icon icon-l bg-mail rounded-s shadow-l">
          <i class="fa fa-envelope font-22"></i>
          <br />
        </a>
        <p class="font-11 opacity-70">Academic</p>
      </div>
      <div class="col-4 mb-n2">
        <a href="#" class="icon icon-l bg-whatsapp rounded-s shadow-l">
          <i class="fab fa-whatsapp font-22"></i>
          <br />
        </a>
        <p class="font-11 opacity-70">Other</p>
      </div>
      <div class="col-4 mb-n2">
        <a href="#" class="close-menu icon icon-l bg-red2-dark rounded-s shadow-l">
          <i class="fa fa-arrow-down font-22"></i>
          <br />
        </a>
        <p class="font-11 opacity-70">Close</p>
      </div>
    </div>
    <div class="divider divider-margins mt-n1 mb-3"></div>
    <carousel :autoplay="true" :nav="false" :dots="false" :margin="20">
      <button class="close-menu icon icon-l bg-red2-dark rounded-s shadow-l"><i class="fa fa-arrow-down font-22"></i></button>
      <img src="https://placeimg.com/200/200/any?1" />

      <img src="https://placeimg.com/200/200/any?2" />

      <img src="https://placeimg.com/200/200/any?3" />

      <img src="https://placeimg.com/200/200/any?4" />
    </carousel>-->
    <div class="content mb-0">
      <h2>Basic Information</h2>
      <p class="mb-5">What are you writing?</p>
      <div class="input-style input-style-2 has-icon input-required">
        <i><fa class="input-icon" :icon="['fal', 'typewriter']"></fa></i>
        <span class="color-highlight">Project Name</span>
        <em>(required)</em>
        <input class="form-control" type="text" v-model="newProject.name" />
      </div>

      <div class="input-style input-style-2 input-required">
        <em>
          <i><fa icon="book" /></i>
        </em>
        <span>Target word count</span>
        <em>
          <i class="fa fa-angle-down"><fa icon="angle-down" /></i>
        </em>
        <select class="form-control" v-model="targetWordCount">
          <option value="default" disabled selected>Target word count</option>
          <option value="notarget">No Word Target</option>
          <option value="1000">Flash Fiction (1000)</option>
          <option value="5000">Short Story (5000)</option>
          <option value="10000">Novelette (10000)</option>
          <option value="25000">Novella (25000)</option>
          <option value="60000">Novel (60000)</option>
          <option value="100000">Epic (100000)</option>
          <option value="custom">Custom</option>
        </select>
      </div>

      <div
        class="input-style input-style-2 has-icon input-required mt-4"
        v-show="targetWordCount === 'custom'"
      >
        <i><fa class="input-icon" icon="abacus"></fa></i>
        <span class="color-highlight input-style-1-active">Custom Target</span>
        <em>(required)</em>
        <input
          type="number"
          class="form-control"
          value
          v-model="customWordCount"
        />
      </div>

      <a
        href="#"
        class="btn btn-full bg-green1-dark btn-m text-uppercase rounded-sm shadow-l mb-3 mt-4 font-900 menu-close"
        @click="save"
        >Create Project</a
      >
    </div>
  </div>
</template>

<script lang="ts">
import { ProjectStatus, Project } from "@/shared/projects";
import { Options, Vue } from "vue-class-component";

@Options({name:"NewProjectActionSheet"})
export default class NewProject extends Vue {
  private newProject = new Project();
  private targetWordCount: number | "custom" | "notarget" = -1;
  private customWordCount = -1;

  public async mounted() {
    window.ww.theme.initAll();
  }

  public async save() {
    if (this.targetWordCount === "custom") {
      this.newProject.targetWordCount = this.customWordCount || 0;
    } else if (this.targetWordCount === "notarget") {
      this.newProject.targetWordCount = null;
    } else {
      this.newProject.targetWordCount = this.targetWordCount as number;
    }

    this.newProject.genre = "";
    this.newProject.state = ProjectStatus.active;
    this.newProject.phases = [];
    if (
      this.newProject.name?.length < 1
    ) {
      return;
      //TODOTthis.$toast.error('someting wrong');
    }
    this.$emit("save", this.newProject);
    this.newProject = new Project();
  }
}
</script>
<style lang="scss" scoped>
.input-style-2 input {
  height: 50px;
}
</style>