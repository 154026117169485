
import $ from "jquery";
import { ProjectStatus, ProjectPhase } from "@/shared/projects";
import { Options, Vue } from "vue-class-component";

@Options({name:"SessionCompleteModal"})
export default class SessionCompleteModal extends Vue {
  //@Prop({ default: true }) private session!: object;
  public wordCount = "";
  public async completeSession() {
    this.$emit("save", parseInt(this.wordCount));
    $("#sessionCompleteModal").hideMenu();
  }
  public async openModal() {
    $("#sessionCompleteModal").showMenu();
  }
}
