
import { ProjectStatus, Project } from "@/shared/projects";
import { Options, Vue } from "vue-class-component";

@Options({name:"NewProjectActionSheet"})
export default class NewProject extends Vue {
  private newProject = new Project();
  private targetWordCount: number | "custom" | "notarget" = -1;
  private customWordCount = -1;

  public async mounted() {
    window.ww.theme.initAll();
  }

  public async save() {
    if (this.targetWordCount === "custom") {
      this.newProject.targetWordCount = this.customWordCount || 0;
    } else if (this.targetWordCount === "notarget") {
      this.newProject.targetWordCount = null;
    } else {
      this.newProject.targetWordCount = this.targetWordCount as number;
    }

    this.newProject.genre = "";
    this.newProject.state = ProjectStatus.active;
    this.newProject.phases = [];
    if (
      this.newProject.name?.length < 1
    ) {
      return;
      //TODOTthis.$toast.error('someting wrong');
    }
    this.$emit("save", this.newProject);
    this.newProject = new Project();
  }
}
