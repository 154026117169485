<template>
  <div
    id="sessionCompleteModal"
    class="menu menu-box-modal rounded-m"
    data-menu-height="240"
    data-menu-width="330"
  >
    <div class="mr-3 ml-3 mt-3">
      <h2 class="text-uppercase font-900 mb-0">How'd you do?</h2>
      <p class="font-11 mb-1">Let's log this session and track our progress</p>
      <div class="input-style has-icon input-style-1 input-required mb-4">
        <fa :icon="['far', 'tally']" class="input-icon"></fa>
        <span>Word count</span>
        <em>(required)</em>
        <input type="number" v-model.number="wordCount" />
      </div>
      <button
        @click="completeSession"
        class="btn btn-full btn-m shadow-l rounded-s bg-highlight text-uppercase font-900 top-20"
      >
        Log it
      </button>
    </div>
  </div>
</template>

<script lang="ts">
import $ from "jquery";
import { ProjectStatus, ProjectPhase } from "@/shared/projects";
import { Options, Vue } from "vue-class-component";

@Options({name:"SessionCompleteModal"})
export default class SessionCompleteModal extends Vue {
  //@Prop({ default: true }) private session!: object;
  public wordCount = "";
  public async completeSession() {
    this.$emit("save", parseInt(this.wordCount));
    $("#sessionCompleteModal").hideMenu();
  }
  public async openModal() {
    $("#sessionCompleteModal").showMenu();
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
