
import $ from "jquery";
import {
  ProjectStatus,
  ProjectPhase,
  Project,
  WritingSession,
} from "@/shared/projects";
import { ProjectService } from "@/services/projectService";
import SessionCompleteModal from "./session-complete-modal.vue";
import { Options, Vue } from "vue-class-component";

@Options({
  name: "SessionModal",
  props: {
    projectId: String,
  },
  components: {
    SessionCompleteModal,
  },
})
export default class SessionModal extends Vue {
  private projectId!: string;
  private newSession!: WritingSession;
  private intervalId = 0;
  private intervalVal = { h: 0, m: 0, s: 0 };
  private quotes = [
    [
      "Amateurs sit and wait for inspiration, the rest of us just get up and go to work.",
      "Stephen King",
    ],
    [
      "Start writing, no matter what. The water does not flow until the faucet is turned on.",
      "Louis L'Amour",
    ],
    [
      "If I waited for perfection, I would never write a word.",
      "Margaret Atwood",
    ],
    [
      "You don't write because you want to say something, you write because you have something to say.",
      "F. Scott Fitzgerald",
    ],
    [
      "The writer who breeds more words than he needs, is making a chore for the reader who reads.",
      "Dr. Seuss",
    ],
    ["The Way Get Started Is To Quit Talking And Begin Doing", "Walt Disney"],
    ["Procrastination is opportunity’s assassin.", "Victor Kiam"],
    ["The journey of a thousand miles begins with a single step.", "Lao Tzu"],
    [
      "If you spend too much time thinking about a thing, you’ll never get it done.",
      "Bruce Lee",
    ],
    [
      "Only put off until tomorrow what you are willing to die having left undone.",
      "Pablo Picasso",
    ],
  ];
  private get randomQuote() {
    const idx = Math.round(Math.random() * (this.quotes.length - 1));
    return {
      author: this.quotes[idx][1],
      quote: this.quotes[idx][0],
    };
  }
  private get pauseRecordIcon() {
    if (this.intervalId) {
      return "pause";
    }
    return "circle";
  }
  public get sessionDuration() {
    return `${this.lead0(this.intervalVal.h)}:${this.lead0(
      this.intervalVal.m
    )}:${this.lead0(this.intervalVal.s)}`;
  }

  public async onPauseOrResume() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
      this.newSession.pauses.push({ start: new Date(), end: null });
    } else {
      const p = this.newSession.pauses[this.newSession.pauses.length - 1];
      p.end = new Date();
      this.everySecond();
      this.intervalId = setInterval(() => this.everySecond(), 100);
    }
  }

  public async complete() {
    //don't call before destroy / it may not exist
    clearInterval(this.intervalId);
    $("#menu-new-session").hideMenu();
    this.newSession.endTime = new Date();
    const modal = this.$refs["scm"] as SessionCompleteModal; //TODO: so bad
    await modal.openModal();
  }

  private lead0(val: number) {
    return `${val < 10 ? "0" + val : val}`;
  }

  public async beforeDestroy() {
    clearInterval(this.intervalId);
  }

  public async openModal() {
    this.newSession = new WritingSession();
    this.newSession.projectId = this.projectId;
    this.newSession.startTime = new Date();
    this.intervalVal = { h: 0, m: 0, s: 0 };
    clearInterval(this.intervalId);
    this.intervalId = setInterval(() => this.everySecond(), 100); //100 to prevent awkward jitter if at 1s
    $("#menu-new-session").showMenu();
  }

  public async save(wordCount: number) {
    this.newSession.wordCount = wordCount;
    this.$emit("save", this.newSession);
    const ps = new ProjectService();
    await ps.saveSession(this.newSession);
    this.newSession = {} as WritingSession;
    //TODO: this.$loading.hide();
  }

  private everySecond() {
    // Get today's date and time
    const now = new Date().getTime();

    // Find the distance between now and the count down date
    const distance =
      now - this.newSession.startTime.getTime() - this.newSession.duration;

    // Time calculations for days, hours, minutes and seconds
    const days = Math.floor(distance / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
      (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((distance % (1000 * 60)) / 1000);
    this.intervalVal = { h: hours, m: minutes, s: seconds };
  }
}
