<template>
  <sticky-page>
    <template v-slot:add-menus>
      <session-modal
        ref="sm"
        :projectId="id"
        @save="sessionComplete"
      ></session-modal>
    </template>
    <div class="card card-style" v-if="currentProject">
      <div class="card mb-0" data-card-height="150">
        <img src="/images/pictures/8w.jpg" class="bottom-20" height="140" />
        <div class="card-top rounded-0 m-2">
          <span
            v-if="currentProject.targetWordCount"
            class="
              badge
              rounded-sm
              font-11
              color-white
              float-right
              py-2
              px-3
              text-uppercase
            "
            :class="statusColor"
            >{{ statusMessage }}</span
          >
        </div>
        <div class="card-bottom m-3">
          <p class="font-11 color-white mb-n1 float-left">
            {{ totalDuration }}
          </p>
          <p
            v-if="currentProject.targetWordCount"
            class="font-11 color-white mb-n1 float-right"
          >
            {{ completePercent }}% Complete
          </p>
          <div class="clearfix"></div>
          <div
            v-if="currentProject.targetWordCount"
            class="progress mt-2"
            style="height: 2px"
          >
            <div
              class="progress-bar border-0 bg-red2-dark text-left"
              role="progressbar"
              :style="'width: ' + completePercent + '%'"
              :aria-valuenow="completePercent"
              aria-valuemin="0"
              aria-valuemax="100"
            ></div>
          </div>
        </div>
        <div class="card-overlay bg-gradient rounded-0"></div>
      </div>
      <div class="content">
        <h2>{{ currentProject.name }}</h2>
        <p>
          {{
            currentProject.description ||
            "My thesis on how to beat procrastination by fiddling with cool apps instead of writing"
          }}
        </p>
        <div class="divider mt-2 mb-3"></div>
        <div class="row">
          <div class="col-4">
            <strong class="color-theme">Duration:</strong>
            <p class="font-12">
              <fa
                :icon="['far', 'stopwatch']"
                class="far fa-stopwatch mr-2"
              ></fa>
              {{ durationShort }}
            </p>
          </div>
          <div class="col-4">
            <strong class="color-theme">Sessions:</strong>
            <p class="font-12">
              <fa :icon="['far', 'calendar']" class="far fa-calendar mr-2"></fa>
              {{ totalSessions }}
            </p>
          </div>
          <div class="col-4">
            <strong class="color-theme">Word count:</strong>
            <p class="font-12">
              <fa :icon="['far', 'tally']" class="fal fa-tally mr-2"></fa>
              {{ totalWords }}
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <strong class="color-theme">Last Active:</strong>
            <p class="font-12">
              <fa :icon="['far', 'calendar']" class="far fa-calendar mr-2"></fa>
              {{ lastActive }}
            </p>
          </div>
        </div>
        <a
          @click="startTimer"
          class="
            btn btn-sm btn-full
            bg-highlight
            rounded-sm
            font-800
            text-uppercase
          "
        >
          <fa :icon="['far', 'stopwatch']" class="mr-2"></fa>Start Writing!
        </a>
      </div>
    </div>
    <div class="card card-style" v-if="currentProject">
      <div class="content my-0">
        <div class="list-group list-custom-small">
          <!-- <router-link :to="`/projects/${this.currentProject.id}/settings`">
              <fa icon="cog" class="color-theme" size="xs" />
              <span>Project Configuration</span>
              <fa icon="angle-right"></fa>
            </router-link> -->
          <router-link :to="`/projects/${this.currentProject.id}/stats`">
            <i class="mr-3">
              <fa icon="chart-bar" class="color-theme font-12" size="sm" />
            </i>
            <span>Statistics</span>
            <i>
              <fa icon="angle-right"></fa>
            </i>
          </router-link>
          <router-link :to="`/projects/${this.currentProject.id}/sessions`">
            <i class="mr-3">
              <fa icon="history" class="color-theme font-12" size="sm" />
            </i>
            <span>Writing Sessions</span>
            <i>
              <fa icon="angle-right"></fa>
            </i>
          </router-link>
        </div>
      </div>
    </div>
  </sticky-page>
</template>

<script lang="ts">
import { formatDuration, formatRelative } from "date-fns";

import {
  ProjectStatus,
  ProjectPhase,
  Project,
  WritingSession,
} from "@/shared/projects";
import { ProjectService } from "@/services/projectService";
import SessionModal from "@/components/session-modal.vue";
import StickyPage from "@/components/sticky-page.vue";
import { LogService } from "@/services/logService";
import { defineComponent } from '@vue/runtime-core';

export default defineComponent({
  name: "ProjectDetailsView",
  components: { StickyPage, SessionModal },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      projectService: {} as ProjectService,
      currentProject: {} as Project | null,
      currentLocation: {} as any,
      ls: new LogService(),
      totalWords: 0,
      totalDuration: "",
      durationShort: "",
      completePercent: 0,
      lastActive: "",
      isLoading: false,
    };
  },
  computed: {
    progressStatus() {
      if (
        !this.currentProject ||
        !this.currentProject.targetWordCount ||
        !this.currentProject.targetEndDate ||
        !this.currentProject.startDate
      ) {
        return "unknown";
      }
      const timelineLen =
        this.currentProject.targetEndDate.getTime() -
        this.currentProject.startDate.getTime();
      const timeSpent = Date.now() - this.currentProject.startDate.getTime();
      const timelinePercent = timeSpent / timelineLen;

      if (this.completePercent < timelinePercent) {
        if (this.completePercent + 15 < timelinePercent) {
          return "red";
        }
        return "yellow";
      }
      return "green";
    },

    statusColor() {
      const colors = {
        red: "bg-red2-dark",
        yellow: "bg-yellow2-dark",
        green: "bg-green2-dark",
        unknown: "",
      };
      return colors[this.progressStatus];
    },

    statusMessage() {
      const msgs = {
        red: "In Jeopardy",
        yellow: "Behind",
        green: "On Track",
        unknown: "",
      };
      return msgs[this.progressStatus];
    },
    
    wordsPerSession(): number {
      return (
        this.totalWords / (this?.currentProject?.writingSessions?.length || 1)
      );
    },

    totalSessions(): number {
      return this.currentProject?.writingSessions?.length || 0;
    },
  },

  async created() {
    this.projectService = new ProjectService();
    await this.loadProject();
  },

  async mounted() {
    window.ww.theme.initAll();
  },

  methods: {
    async loadProject() {
      const cp = await this.projectService.getProject(this.id);
      if (!cp) {
        //this.$snack({msg:`Project: ${this.id} not found`, type: 'error'});
        this.$router.push({ name: "oops", params: { resource: "Project" } });
        return;
      }
      let td = 0,
        tw = 0;
      cp.writingSessions?.forEach((ws:WritingSession) => {
        const end = ws.endTime || new Date();
        let dur = end.getTime() - new Date(ws.startTime).getTime();
        if (ws.pauses) {
          ws.pauses.forEach((p) => {
            dur -= p.end
              ? new Date(p.end)?.getTime() - new Date(p.start).getTime()
              : 0;
          });
        }
        dur = Math.floor(dur / 1000);
        td += dur;
        tw += ws.wordCount;
      });
      this.totalWords = tw;
      // this.totalDuration = formatDuration({
      //   years: Math.round(td / (24 * 3600 * 30 * 12)),
      //   months: Math.round((td / (24 * 3600 * 30)) % 12),
      //   days: Math.round((td / (24 * 3600)) % 30),
      //   hours: Math.round((td / 3600) % 24),
      //   minutes: Math.round((td / 60) % 60),
      //   seconds: Math.round(td % 60),
      // });
      const hours = Math.floor(td / 3600);
      const minutes = Math.floor(td / 60) % 60;
      this.totalDuration = `${hours} hour${
        hours > 1 ? "s" : ""
      }, ${minutes} minute${minutes > 1 ? "s" : ""}`;
      this.durationShort = `${hours < 10 ? "0" + hours : hours}:${
        minutes < 10 ? "0" + minutes : minutes
      }`;
      this.lastActive = formatRelative(
        new Date(cp.lastModified || new Date()),
        new Date()
      );
      this.completePercent =
        Math.round(((tw || 0) / (cp.targetWordCount || 1)) * 1000) / 10;
      this.currentProject = cp;
    },

    async startTimer() {
      this.isLoading = true;

      const modal = this.$refs["sm"] as SessionModal; //TODO: so bad
      await modal.openModal();
    },

    async sessionComplete(session: WritingSession) {
      await this.projectService.saveSession(session);
      await this.loadProject();
    },

    loadLocation() {
      if (!navigator.geolocation) {
        return;
      }
      navigator.geolocation.getCurrentPosition(
        (coords) => {
          this.currentLocation = coords;
        },
        (err) => {
          this.ls.error(err.message);
        }
      );
    },
  },
});
</script>